export const industries = [
  "Agriculture",
  "Banking and Financial Services",
  "Broadcasting",
  "Construction",
  "Energy and Utilities",
  "Entertainment",
  "Healthcare",
  "Insurance",
  "Manufacturing",
  "Mining and Extractive Industries",
  "Nonprofits",
  "Real Estate",
  "Retail",
  "Technology",
  "Telecommunications",
  "Transportation",
  "Hospitality",
  "Pharmaceuticals and Life Sciences",
  "Public Sector (Governmental Accounting)",
  "Education",
  "Professional Services",
  "Media and Publishing",
  "Aerospace and Defense",
  "Consumer Goods",
  "Automotive",
];
export const revenueSources = [
  "Product Sales Revenue",
  "Service Revenue",
  "Subscription Revenue",
  "Licensing Revenue",
  "Royalty Revenue",
  "Commission Revenue",
  "Advertising Revenue",
  "Interest Revenue",
  "Dividend Revenue",
  "Rent Revenue",
  "Consulting Revenue",
  "Franchise Revenue",
  "Membership Revenue",
  "Sponsorship Revenue",
  "Government Grants and Subsidies",
  "Donation and Contribution Revenue (for Nonprofits)",
  "Tuition and Fees (Education Sector)",
  "Insurance Premium Revenue",
  "Utility Revenue (e.g., electricity, water)",
  "Brokerage Fees and Transaction Revenue",
  "Contract Revenue (e.g., long-term construction contracts)",
  "Performance-Based Revenue",
  "Deferred Revenue (when performance obligations are met)",
  "Revenue from Sale of Assets",
  "Lease Revenue",
  "Gaming and Lottery Revenue",
  "Transportation and Freight Revenue",
  "Healthcare Service Revenue",
  "Software and Cloud Service Revenue",
  "Professional Service Revenue (e.g., legal, accounting, engineering)",
];
export const primaryExpenses = [
  "Cost of Goods Sold (COGS)",
  "Salaries and Wages",
  "Employee Benefits",
  "Payroll Taxes",
  "Rent Expense",
  "Utilities Expense",
  "Depreciation Expense",
  "Amortization Expense",
  "Insurance Expense",
  "Marketing and Advertising Expense",
  "Travel and Entertainment Expense",
  "Office Supplies Expense",
  "Professional Fees (e.g., legal, accounting)",
  "Repairs and Maintenance Expense",
  "Research and Development (R&D) Expense",
  "Training and Development Expense",
  "Interest Expense",
  "Bad Debt Expense",
  "Taxes (e.g., income, property, sales)",
  "Inventory Write-offs or Adjustments",
  "Loss on Disposal of Assets",
  "Technology and Software Expenses",
  "Dues and Subscriptions",
  "Shipping and Freight Costs",
  "Telephone and Communication Expenses",
  "Consulting Fees",
  "Charitable Contributions",
  "Licensing Fees",
  "Bank Fees and Charges",
  "Warranty Costs",
  "Lease Expenses (Operating and Finance)",
  "Contractor Payments",
  "Miscellaneous Expenses",
];
export const accountingStandards = [
  "US GAAP",
  "IFRS",
  "Ind AS",
  "UK GAAP",
  "ASPE",
  "J-GAAP",
  "AASB",
  "NZ IFRS",
  "German GAAP",
];
export const businessDepartments = [
  "Sales",
  "Production",
  "Human Resources (HR)",
  "Finance",
  "Marketing",
  "Research and Development (R&D)",
  "Customer Service",
  "Information Technology",
];
